@import "../../../styles/common";

.container {
  font-size: 14px;
  line-height: 21px;
  color: $gray1;
  margin-bottom: get-xd-spacing-size($spacing-size: 11px, $font-size: 14px + 14px, $line-height: 21px + 21px);
  word-break: break-all;

  &:last-child {
    margin-bottom: 0;
  }
}

.card {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image {
  $size: 16px;

  width: $size;
  height: $size;
  margin-right: 6px;
}
